export default <T extends string = ''>(key: T = '' as T) => {
  const pendingCount = ref(0)

  const startLoading = () => pendingCount.value++
  const stopLoading = () => pendingCount.value--

  const isLoading = computed(() => pendingCount.value > 0)

  interface Loader {
    isLoading: ComputedRef<boolean>
    startLoading: () => number
    stopLoading: () => number
  }

  return {
    [`isLoading${capitalize(key)}`]: isLoading,
    [`startLoading${capitalize(key)}`]: startLoading,
    [`stopLoading${capitalize(key)}`]: stopLoading,
  } as {
    [K in keyof Loader as `${K}${Capitalize<T>}`]: Loader[K]
  }
}
